import React from "react";

import './notFound.css';

function NotFound() {
    return (
        <div className="notfound">
            <h1>Oops</h1>
            <h2>404 Not Found</h2>
            <a href="/">Retour à la page d'accueuil</a>
        </div>
    );
}

export default NotFound;