import React, { useState, useEffect } from 'react';
import './PE-lib.css';

const AutoComplete = ({ data, dark, label, placeholder, value, setValue }) => {
    const [inputValue, setInputValue] = useState(value || '');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [hasFocusedOnce, setHasFocusedOnce] = useState(false);
    const [crossVisible, setCrossVisible] = useState(false);

    // useEffect(() => {
    //     if (inputValue !== value) {
    //         setValue(inputValue);
    //     }
    // }, [inputValue, value, setValue]);

    useEffect(() => {
        if (isFocused && !hasFocusedOnce) {
            setFilteredSuggestions(data);
            setHasFocusedOnce(true);
        }
    }, [isFocused, hasFocusedOnce, data]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setInputValue(inputValue);
        if (Array.isArray(data)) {
            filterSuggestions(inputValue);
        }
    };

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    const filterSuggestions = (inputValue) => {
        const filteredSuggestions = data.filter((item) =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredSuggestions(filteredSuggestions);
    };

    const handleLiMouseOver = (event) => {
        event.target.style.backgroundColor = dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';
    };

    return (
        <div className='autoc-container'>
            <label
                className={`input-label ${isFocused || inputValue ? 'active' : ''}`}
                style={{
                    color: 'rgb(120, 120, 120)',
                    fontWeight: '500',
                    fontSize: '14px',
                }}
            >
                {label || ''}
            </label>

            <div className='show-open'
                onClick={() => setIsFocused(!isFocused)}
            >
                <p
                    className={`arrows${isFocused ? ' active' : ''}`}
                    style={{
                        color: dark ? 'rgb(120, 120, 120)' : 'rgb(50, 50, 50)',
                    }}
                >
                    {'<'}
                </p>
            </div>

            {
                crossVisible && inputValue && (
                    <div className='remove'
                        onClick={() => {
                            setInputValue('');
                            setFilteredSuggestions(data);
                        }}

                        onMouseOver={(event) => {
                            setCrossVisible(true);
                        }}
                        onMouseOut={(event) => {
                            setCrossVisible(false);
                        }}
                    >
                        <svg width="10" height="10" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
                            style={{
                                transition: 'all 0.3s ease',
                            }}
                        >
                            <line x1="0" y1="100" x2="100" y2="0" stroke-width="15" stroke={dark ? 'rgb(120, 120, 120)' : 'rgb(50, 50, 50)'} />
                            <line x1="0" y1="0" x2="100" y2="100" stroke-width="15" stroke={dark ? 'rgb(120, 120, 120)' : 'rgb(50, 50, 50)'} />
                        </svg>
                    </div>
                )
            }

            <input
                placeholder={placeholder}
                type='text'
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onMouseOver={(event) => {
                    event.target.style.backgroundColor = dark ? 'rgb(70, 70, 70)' : 'rgb(230, 230, 230)';
                    setCrossVisible(true);
                }}
                onMouseOut={(event) => {
                    event.target.style.backgroundColor = dark ? 'rgb(50, 50, 50)' : 'rgb(244, 244, 245)';
                    setCrossVisible(false);
                }}

                style={{
                    backgroundColor: dark ? 'rgb(50, 50, 50)' : 'rgb(244, 244, 245)',
                    color: dark ? 'white' : 'black',
                    border: 'none',
                    padding: label ? '35px 35px 10px 15px' : '15px',
                    borderRadius: '15px',
                    fontSize: '16px',
                    width: '100%',
                    height: '70px',
                    transition: 'all 0.3s ease',
                    boxSizing: 'border-box',
                }}
            />

            <div className={`suggestions${isFocused ? ' active' : ''}`}
                style={{
                    boxShadow: dark ? '0px 0px 10px 0px rgba(0,0,0,0.1)' : '0px 0px 10px 0px rgba(0,0,0,0.1)',
                }}
            >
                <ul
                    className="suggestion"
                    style={{
                        backgroundColor: dark ? 'rgb(40, 40, 40)' : 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        color: dark ? 'white' : 'black',
                        textAlign: 'left',
                        borderRadius: '15px',
                        border: dark ? 'none' : '1px solid rgb(230, 230, 230)',
                    }}
                >
                    {
                        filteredSuggestions.length > 1 && (
                            <div className='transition-top'
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '25px',
                                    background: dark ? 'linear-gradient(180deg, rgba(40, 40, 40, 1) 20%, rgba(50, 50, 50, 0))' : 'none',
                                }}
                            >
                            </div>
                        ) || null
                    }

                    <div className='scroll-items'>
                        {filteredSuggestions.length > 0 ? (
                            filteredSuggestions.map((suggestion, index) => (
                                <li key={index}
                                    style={{
                                        padding: '10px 15px',
                                        margin: '5px 0',
                                    }}

                                    onMouseOver={handleLiMouseOver}
                                    onMouseOut={(event) => event.target.style.backgroundColor = 'transparent'}

                                    onClick={() => {
                                        setInputValue(suggestion.name);
                                        setValue(suggestion.id);
                                        setFilteredSuggestions([]);
                                    }}
                                >
                                    {suggestion.name}
                                </li>
                            ))
                        ) : (
                            <li style={{
                                padding: '10px 15px',
                                margin: '5px 0',
                                color: dark ? 'rgb(120, 120, 120)' : 'black',
                            }}>
                                No results found.
                            </li>
                        )}
                    </div>

                    {
                        filteredSuggestions.length > 1 && (
                            <div className='transition-bottom'
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '30px',
                                    background: dark ? 'linear-gradient(0deg, rgba(40, 40, 40, 1) 20%, rgba(50, 50, 50, 0))' : 'linear-gradient(0deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0))',
                                }}
                            >
                            </div>
                        ) || null
                    }
                </ul>
            </div>
        </div>
    );
};

export default AutoComplete;
