import React, { useState, useEffect } from 'react';

import "./login.css";

import NavBar from '../../components/navBar/NavBar';

const Login = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submit = () => {
        fetch(`${apiUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(response => response.json())
        .then(data => {
            window.localStorage.setItem('token', data.user.token);
            window.localStorage.setItem('user', JSON.stringify(data.user));
            window.location.href = '/dashboard';
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    return (
        <div className="login">
            <NavBar />
            <h1>Login</h1>
            <div className='contentlogin'>
                <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                <button type="submit" onClick={() => submit()}>
                    Login
                </button>
            </div>
        </div>
    );
};

export default Login;