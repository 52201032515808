import React from 'react'
import { useState } from 'react';

// <!-- Vendor CSS -->
import '../../style/vendor/bootstrap/css/bootstrap.min.css';
import '../../style/vendor/fontawesome-free/css/all.min.css';
import '../../style/vendor/animate/animate.compat.css';
import '../../style/vendor/simple-line-icons/css/simple-line-icons.min.css';
import '../../style/vendor/owl.carousel/assets/owl.carousel.min.css';
import '../../style/vendor/owl.carousel/assets/owl.theme.default.min.css';
import '../../style/vendor/magnific-popup/magnific-popup.min.css';

// <!-- Theme CSS -->
import '../../style/css/theme.css';
import '../../style/css/theme-elements.css';
import '../../style/css/theme-blog.css';
import '../../style/css/theme-shop.css';

// <!-- Demo CSS -->
import '../../style/css/demos/demo-construction-2.css';

// <!-- Skin CSS -->
import '../../style/css/skins/skin-construction-2.css';

// <!-- Theme Custom CSS -->
import '../../style/css/custom.css';

import img from '../../style/mesimages/logo.png'

import 'animate.css';
import './NavBar.css';

function NavBar() {

    const [burger, setBurger] = useState(false);

    const handleClickBurger = () => {
        setBurger(!burger);
    }


    const [content, setContent] = useState(
        <div className='responsiveNav animated slideInDown'>
            <ul>
                <li>
                    <a href="/">Accueil</a>
                </li>
                <li>
                    <a href="/contact">Nous contacter</a>
                </li>

                <li>
                    <a href="/informations">À propos</a>
                </li>
            </ul>
        </div>
    );


    return (
        <div className='body'>
            <header id="header" class="header-effect-shrink bg-color-tertiary custom-header" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyChangeLogo': true, 'stickyStartAt': 100, 'stickyHeaderContainerHeight': 83}">
                <div class="header-body border-0 box-shadow-none">
                    <div class="header-container container-fluid bg-secondary px-0">
                        <div class="header-row">
                            <div class="header-column bg-color-primary flex-grow-0 px-3 px-lg-5">
                                <div class="header-row">
                                    <div class="header-logo-perro">
                                        <a href="/">
                                            <img alt="Porto" class="img-logo-perro" width="123" height="32" src={img} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="header-column justify-content-end justify-content-lg-start px-lg-5">
                                <div class="header-row pe-3">
                                    <div class="header-nav header-nav-links order-2 order-lg-1 header-nav-light-text flex-grow-0 justify-content-start">
                                        <div class="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1 ">
                                            <nav class="collapse">
                                                <ul class="nav nav-pills" id="mainNav">
                                                    <li class="dropdown">
                                                        <a class="dropdown-item active" href="/">
                                                            Accueil
                                                        </a>
                                                    </li>

                                                    <li class="dropdown">
                                                        <a class="dropdown-item" href="/informations" onClick={() => localStorage.setItem('CurrentInfo', 'gaz_speciaux')}>
                                                            À propos
                                                        </a>
                                                    </li>

                                                    <li class="dropdown">
                                                        <a class="dropdown-item" href="/contact">
                                                            Nous contacter
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <button class="btn header-btn-collapse-nav" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav" onClick={handleClickBurger}>
                                            <i class="fas fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="header-row d-none d-lg-inline-flex justify-content-end">
                            <div class="order-1 order-lg-2 pe-4 d-none d-xl-block">
                                <div>
                                    <div class="feature-box feature-box-style-2 align-items-center p-relative top-8 px-2">
                                        <div class="feature-box-icon">
                                            <i class="fas fa-mobile-alt text-9 p-relative bottom-8 text-color-light"></i>
                                        </div>
                                        <div class="feature-box-info ps-0">
                                            <p class="text-color-light opacity-8 text-uppercase line-height-1 text-2 pb-0 mb-2">APPELEZ NOUS</p>
                                            <p class="textphone number text-uppercase text-color-light font-weight-black letter-spacing-minus-1 line-height-1 text-5 pb-0"><a href="tel:+0383496554" class="text-color-light text-color-hover-primary text-decoration-none">03 83 49 65 54</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {burger ? (
                        <div className='responsiveNav animated slideInDown'>
                            <ul>
                                <li>
                                    <a href="/">Accueil</a>
                                </li>
                                <li>
                                    <a href="/contact">Nous contacter</a>
                                </li>
                                <li>
                                    <a onClick={() => localStorage.setItem('CurrentInfo', 'gaz_speciaux')} href="/informations">À propos</a>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </header>


        </div>
    )
}

export default NavBar
