import React, { useEffect, useState } from 'react';

import NavBar from '../../components/navBar/NavBar'

import './Accueuil.css'

import backgroundVideo from '../../media/video.mp4';

import { BsCloudHaze } from 'react-icons/bs';
import { GiThermometerCold } from 'react-icons/gi';
import { AiOutlineCloudServer } from 'react-icons/ai';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { PiThermometerColdThin } from 'react-icons/pi';

import ReactDOM from 'react-dom';

import img1 from '../../style/img/demos/construction-2/about-us/about-1.jpg';
import img2 from '../../style/img/demos/construction-2/about-us/about-2.jpg';

import imgPage1 from '../../mesimages/page_en_tete/1.jpg';
import imgPage2 from '../../mesimages/Detection.jpg';
import imgPage3 from '../../mesimages/tubes.jpg';
import imgPage4 from '../../mesimages/processIndu.jpg';
import imgPage5 from '../../mesimages/page_en_tete/5.jpg';

import clim from '../../mesimages/clim.jpg';

import imgLogo from '../../style/mesimages/logo.png';

import certif1 from '../../mesimages/certif1.png';
import certif2 from '../../mesimages/maselogo.png';
import logomase from '../../mesimages/certif2.jpg'

import certifpdf1 from '../../mesimages/certifmase.pdf';
import certifpdf2 from '../../mesimages/certifveritas.pdf';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import gaz_spe1 from '../../mesimages/Gaz_Speciaux/gaz_spec_panoplie.jpg';
import clim1 from '../../media/froid_clim/1.jpg';
import cryo1 from '../../mesimages/Application_Cryogénie/20221117_174252074.jpg';
import detection_gaz from '../../mesimages/Détection_Gaz_et_Rugulation/20221117_174308823.jpg';


// ../../style/img/demos/construction-2/portfolio/portfolio-1.jpg
// img/demos/construction-2/about-us/about-1.jpg

function Accueuil() {

	const [nbDaySince, setNbDaySince] = useState(0);

	useEffect(() => {
		localStorage.setItem('CurrentInfo', 'none');
		const actualDate = new Date();
		const startDate = new Date('2023-06-30');

		const differenceEnMillisecondes = actualDate - startDate;
		let differenceEnJours = differenceEnMillisecondes / (1000 * 60 * 60 * 24);

		differenceEnJours = Math.floor(differenceEnJours);
		setNbDaySince(differenceEnJours + 400);
	}, []);

	const setInfoTo = (info) => {
		localStorage.setItem('CurrentInfo', info);
	}

	return (
		<div className='acc'>
			<div className="background-container">
				<video autoPlay muted loop className="background-video">
					<source src={backgroundVideo} type="video/mp4" />
				</video>

				<div className='navhead'>
					<NavBar />
				</div>

				<div className='text'>
					<p className='h2o'>
						H2
					</p>

					<p className='O2'>
						O2
					</p>

					<p className='CO2'>
						CO2
					</p>

					<p className='NH3'>
						NH3
					</p>

					<p className='N2'>
						N2
					</p>

					<p className='CH4'>
						CH4
					</p>

					<div className='content_acceuil'>
						<div className='content_right'>
							<h1>
								GAZ SPÉCIAUX ET FROID
							</h1>
						</div>

						<div className='appear'>
							<div className='appear_content'>
								<div className='boite' onClick={() => {
									setInfoTo("gaz_speciaux");
									window.location.href = '/informations';
								}}>
									<div className='up_case'>
										<BsCloudHaze />
									</div>
									<h1>Gaz Spéciaux</h1>

									<a href='/informations'>Lire plus</a>
								</div>


								<div className='boite' onClick={() => {
									setInfoTo("cryogenie");
									window.location.href = '/informations';
								}}>
									<div className='up_case'>
										<AiOutlineCloudServer />
									</div>
									
									<h1>Cryogénie</h1>

									<a href='/informations'>Lire plus</a>
								</div>

								<div className='boite' onClick={
									() => {
										setInfoTo("detection_gaz");
										window.location.href = '/informations';
									}}>
									<div className='up_case'>
										<HiOutlineNewspaper />
									</div>

									<h1>Detection Gaz</h1>

									<a href='/informations'>Lire plus</a>
								</div>
								
								<div className='boite' onClick={() => {
									setInfoTo("froid_et_clim");
									window.location.href = '/informations';
								}}>
									<div className='up_case'>
										<GiThermometerCold />
									</div>
									
									<h1>Froid et Clim</h1>

									<a href='/informations'>Lire plus</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='who'>
				<div className='whoo'>
					<div className='who_content'>
						<h1>
							Qui sommes nous ?
						</h1>

						<p>
							Société installée au nord de Nancy, nous intervenons depuis plus
							de 25 ans dans les métiers du GAZ pour les plus grands leaders mondiaux de
							production et distribution de GAZ Spéciaux.
							Nous intervenons dans les secteurs de l’Industrie, la Recherche,
							l’Agro-alimentaire, la Cryogénie et le Médical dans le Grand EST.
						</p>
					</div>

					<div className='who_right'>
						<img src={certif2} alt='logo' />
					</div>
				</div>
			</div>

			<div className='back_carrou'>

				<div className='carrousel'>
					<p className='activitéP'>Que faisons nous ?</p>
					<h2 className='activité'>Nos activitées</h2>
					<div className='carrousel-item'>
						<div className='boite'
							onClick={
								() => {
									setInfoTo("gaz_speciaux");
									window.location.href = '/informations';
								}
							}
						>
							<img src={gaz_spe1} alt='gaz_spe' />
							<div className='case'>
								<div className='up_case'>
									<BsCloudHaze />
								</div>
								<div className='middle_case'>
									<h2>Gaz Spéciaux</h2>
									<p>
										Laboratoire - Industrie - Agroalimentaire - Medical
									</p>
								</div>
								<div className='down_case'>
									<a
										href='/informations'
										onClick={
											() => {
												setInfoTo("gaz_speciaux");
												window.location.href = '/informations';
											}
										}
									>Lire plus </a>
								</div>
							</div>
						</div>

						<div className='boite'
							onClick={
								() => {
									setInfoTo("cryogenie");
									window.location.href = '/informations';
								}
							}
						>
							<img src={cryo1} alt='cryo' />
							<div className='case'>
								<div className='up_case'>
									<GiThermometerCold />
								</div>
								<div className='middle_case'>
									<h2>Cryogénie</h2>
									<p>
										Tunnel - Extraction - Reservoir
									</p>
								</div>
								<div className='down_case'>
									<a
										href='/informations'
										onClick={
											() => {
												setInfoTo("cryogenie");
												window.location.href = '/informations';
											}
										}
									>Lire plus </a>
								</div>
							</div>
						</div>

						<div className='boite'
							onClick={
								() => {
									setInfoTo("detection_gaz");
									window.location.href = '/informations';
								}
							}
						>
							<img src={detection_gaz} alt='detection_gaz' />
							<div className='case'>
								<div className='up_case'>
									<AiOutlineCloudServer />
								</div>
								<div className='middle_case'>
									<h2>Detection Gaz</h2>
									<p>
										Protection des personnes et de biens
									</p>
								</div>
								<div className='down_case'>
									<a
										href='/informations'
										onClick={
											() => setInfoTo("detection_gaz")
										}
									>Lire plus </a>
								</div>
							</div>
						</div>


						<div className='boite'
							onClick={
								() => {
									setInfoTo("froid_et_clim");
									window.location.href = '/informations';
								}

							}
						>
							<img src={clim1} alt='clim' />
							<div className='case'>
								<div className='up_case'>
									<PiThermometerColdThin />
								</div>
								<div className='middle_case'>
									<h2>Froid et Clim</h2>
									<p>
										Certifié Bureau Veritas, n° 1132038 - R2
									</p>
								</div>
								<div className='down_case'>
									<a
										href='/informations'
										onClick={
											() => setInfoTo("froid_et_clim")
										}
									>Lire plus </a>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<section class="section custom-angled section-angled bg-tertiary border-top-0 pb-0 pb-lg-5 mb-5 mb-lg-0">
				<div class="section-angled-layer-top bg-light"></div>
				<div class="section-angled-layer-bottom bg-light d-none d-lg-block"></div>
				<div class="section-angled-content mb-0 mb-lg-5">
					<div class="container py-5 container-xl-custom custom-container">
						<div class="row align-items-center justify-content-center pt-5 pb-lg-5">
							<div class="col-xl-6 mt-lg-5 mt-xl-0 pb-lg-5 mb-lg-5">
								<div class="ps-md-4 mt-5">
									<div class="row">
										<div class="col">
											<h3 class="text-secondary font-weight-bold text-capitalize text-7 mb-2">Nos certifications</h3>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<p class="text-4 pt-2 mb-4">
												Retrouvez nos certifications <a href={certifpdf1} target="_blank">Mase</a> ainsi que <a href={certifpdf2} target="_blank">Bureau Veritas</a>
											</p>
											{/* <p class="mb-3">
													Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec vulputate eget, arcu in enim justo. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
												</p> */}
											<div class="row mb-4 counters counters-sm text-secondary">
												<div class="col-6 col-lg-3 mb-4 mb-lg-0 mt-4">
													<div class="counter">
														<strong data-to="35" data-append="+">{nbDaySince}j</strong>
														<label class="text-2 pt-1">Sans accident de travail avec arret</label>
													</div>
												</div>
												<div class="col-6 col-lg-3 mb-4 mb-lg-0 mt-4">
													<div class="counter">
														<strong data-to="240" data-append="+">0</strong>
														<label class="text-2 pt-1">Taux de fréquence</label>
													</div>
												</div>
												<div class="col-6 col-lg-3 mb-4 mb-sm-0 mt-4">
													<div class="counter">
														<strong data-to="2000" data-append="+">0</strong>
														<label class="text-2 pt-1">Taux de gravité</label>
													</div>
												</div>
												<div class="col-6 col-lg-3 mb-4 mb-sm-0 mt-4">
													<div class="counter">
														<strong data-to="2000" data-append="+">90%</strong>
														<label class="text-2 pt-1">Du personnel formé SST</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


			{/* <div className='commentaire'>

                </div> */}



			<footer id="footer" class="bg-color-secondary border-top-0 mt-0 custom-footer">
				<div class="container container-xl-custom py-md-4">
					<div class="row justify-content-md-center py-5">
						<div class="col-md-12 col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start mb-5 mb-lg-0">
							<a href="#"><img src={imgLogo} alt="Logo" class="img-fluid logo" /></a>
						</div>
						<div class="col-md-3 text-center text-md-start">
							<p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Nous Retrouver</p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Adresse</p>
							<p class="text-3 mb-2 text-color-light">13 rue du Chambré, 54760, Faulx - France</p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">TELEPHONE</p>
							<p class="text-3 mb-2 text-color-light"><a href="tel:+1234567890" class="text-color-light">03 83 49 65 54</a></p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">EMAIL</p>
							<p class="text-3 mb-2 "><a href="mailto:info@porto.com" class="text-color-light">contact@sarlperrollaz.fr</a></p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Heures d'ouverture</p>
							<p class="text-3 mb-3 text-color-light">Lun - Ven | 8:30 - 12:30 / 14:00 - 17:00</p>
							<ul class="social-icons social-icons-dark social-icons-clean">
								<li class="social-icons-linkedin">
									<a href="https://www.linkedin.com/in/didier-perrollaz-568b4b140/" target="_blank" title="Linkedin">
										<i class="fab fa-linkedin font-weight-semibold"></i>
									</a>
								</li>
								<li class="social-icons-facebook">
									<a href="https://www.facebook.com/Perrollaz54/" target="_blank" title="Facebook">
										<i class="fab fa-facebook-f font-weight-semibold"></i>
									</a>
								</li>
							</ul>
						</div>
						<div class="col-md-4 text-center text-md-start mt-5 mt-md-0 mb-5 mb-lg-0">
							<p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Navigation</p>
							<div class="row opacity-7">
								<div class="col-md-5">
									<p class="mb-0"><a href="#" class="text-3 text-color-light link-hover-style-1">Accueil</a></p>
									<p class="mb-0"><a href="/informations" onClick={() => localStorage.setItem('CurrentInfo', 'gaz_speciaux')} class="text-3 text-color-light link-hover-style-1">à propos</a></p>
									<p class="mb-0"><a href="/contact" class="text-3 text-color-light link-hover-style-1">Nous contacter</a></p>
									<p class="mb-0"><a href="/login" class="text-3 text-color-light link-hover-style-1">Se connecter</a></p>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="container container-xl-custom">
					<div class="footer-copyright footer-copyright-style-2 bg-color-secondary">
						<div class="py-2">
							<div class="row py-4">
								<div class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">
									<p class="text-3 text-color-light opacity-7">Tal-Web construction. © 2023. All Rights Reserved</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Accueuil;