import React, { useState, useEffect } from 'react';

import NavBar from '../../components/navBar/NavBar'

import './information.css'

import imgLogo from '../../style/mesimages/logo.png';

import imgPage1 from '../../mesimages/page_en_tete/1.jpg';
import imgPage2 from '../../mesimages/Detection.jpg';
import imgPage3 from '../../mesimages/tubes.jpg';
import imgPage4 from '../../mesimages/processIndu.jpg';
import imgPage5 from '../../mesimages/page_en_tete/5.jpg';

import gaz_spe1 from '../../media/gaz_speciaux/gazs1.jpg';
import gaz_spe2 from '../../media/gaz_speciaux/gazs2.jpg';
import gaz_spe3 from '../../media/gaz_speciaux/gazs3.jpg';
import gaz_spe4 from '../../media/gaz_speciaux/gazs4.jpg';
import gaz_spe5 from '../../media/gaz_speciaux/gazs5.jpg';
import gaz_spe6 from '../../media/gaz_speciaux/gazs6.jpg';

import clim1 from '../../media/froid_clim/1.jpg';
import clim2 from '../../mesimages/clim2.jpg';
import clim3 from '../../mesimages/clim3.jpg';

import cryo1 from '../../mesimages/Application_Cryogénie/20221117_174252074.jpg';
import cryo2 from '../../mesimages/Application_Cryogénie/IMG_20160118_180342.jpg';
import cryo3 from '../../mesimages/Application_Cryogénie/20220127_133216421.jpg';
import cryo4 from '../../media/cryogenie/cryo.jpg';

import detection_gaz from '../../mesimages/Détection_Gaz_et_Rugulation/20221117_174308823.jpg';
import detection_gaz2 from '../../mesimages/Détection_Gaz_et_Rugulation/025.jpg';
import detection_gaz3 from '../../media/detection/detec1.jpg';

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

function Information(props) {

	const [infoToDisplay, setGetInfoToDisplay] = useState('');
	const [content, setContent] = useState('');
	const [label, setLabel] = useState('');
	const [animationKey, setAnimationKey] = useState(0);
	const [imageDisplay, setImageDisplay] = useState('');

	const [tab, setTab] = useState([
		{
			id: 1,
			name: "froid_et_clim",
			images: [clim1, clim2, clim3],
			index: 0
		},
		{
			id: 2,
			name: "detection_gaz",
			images: [detection_gaz3, detection_gaz, detection_gaz2],
			index: 0
		},
		{
			id: 3,
			name: "cryogenie",
			images: [cryo1, cryo2, cryo3, cryo4],
			index: 0
		},
		{
			id: 4,
			name: "gaz_speciaux",
			images: [gaz_spe1, gaz_spe2, gaz_spe3, gaz_spe4, gaz_spe5, gaz_spe6],
			index: 0
		}
	]);

	useEffect(() => {
		let check = localStorage.getItem('CurrentInfo');
		if (check == null) {
			localStorage.setItem('CurrentInfo', 'gaz_speciaux');
		}
	}, []);

	useEffect(() => {
		setGetInfoToDisplay(localStorage.getItem('CurrentInfo'));
	}, []);

	useEffect(() => {
		setContentTo();
	}, [infoToDisplay]);

	const switchInfoIncrease = () => {
		for (let i = 0; i < tab.length; i++) {
			if (tab[i].name === infoToDisplay) {
				if (tab[i].id === 4) {
					setGetInfoToDisplay(tab[0].name);
					setAnimationKey(animationKey + 1);
				} else {
					setGetInfoToDisplay(tab[i + 1].name);
					setAnimationKey(animationKey + 1);
				}
				break;
			}
		}
	}

	const increaseIndexOfCurrentInfo = () => {
		console.log(tab[tab.findIndex(x => x.name === infoToDisplay)].index);

		for (let i = 0; i < tab.length; i++) {
			if (tab[i].name === infoToDisplay) {
				if (tab[i].index === tab[tab.findIndex(x => x.name === infoToDisplay)].images.length - 1) {
					tab[i].index = 0;
					setContentTo();
				} else {
					tab[i].index = tab[i].index + 1;
					setContentTo();
				}
				break;
			}
		}
	}

	const decreaseIndexOfCurrentInfo = () => {
		for (let i = 0; i < tab.length; i++) {
			if (tab[i].name === infoToDisplay) {
				if (tab[i].index === 0) {
					tab[i].index = 3;
					setContentTo();
				} else {
					tab[i].index = tab[i].index - 1;
					setContentTo();
				}
				break;
			}
		}
	}

	const switchInfoDecrease = () => {
		for (let i = 0; i < tab.length; i++) {
			if (tab[i].name === infoToDisplay) {
				if (tab[i].id === 1) {
					setGetInfoToDisplay(tab[3].name);
					setAnimationKey(animationKey + 1);
				} else {
					setGetInfoToDisplay(tab[i - 1].name);
					setAnimationKey(animationKey + 1);
				}
				break;
			}
		}
	}

	const setContentTo = () => {
		if (infoToDisplay === "froid_et_clim") {
			setLabel("Froid et Clim");
			setImageDisplay(tab[0].images[tab[tab.findIndex(x => x.name === infoToDisplay)].index]);
			setContent(
				<div>
					<h1>
						Froid et clim
					</h1>

					<p>
						Étude, réalisation et maintenance : 
						<br /> 
						- Installation de climatisation PAC Air/Air (tertiaire et particuliers).
						- Installation de groupe froid, traitement de l’air et stockage CO2.

					</p>
				</div>
			);
		} else if (infoToDisplay === "detection_gaz") {
			setLabel("Détection Gaz");
			setImageDisplay(tab[1].images[tab[tab.findIndex(x => x.name === infoToDisplay)].index]);
			setContent(
				<div>
					<h1>
						Détéction Gaz
					</h1>

					<p>
						Étude, réalisation et maintenance : 
						<br />
						- Des installations de détection Gaz pour la protection des personnes et des biens.
						Partenaire et agréée Oldham.

						<br />
						<br />
						Nos domaines d’activités : Agro - alimentaire, Laboratoire, Application Cryogénique et Industrie.
					</p>
				</div>
			);
		} else if (infoToDisplay === "cryogenie") {
			setLabel("Cryogénie");
			setImageDisplay(tab[2].images[tab[tab.findIndex(x => x.name === infoToDisplay)].index]);
			setContent(
				<div>
					<h1>
						Cryogenie
					</h1>

					<p>
						Étude, réalisation et maintenance : 
						<br /> 
						- Réseaux inox isolé sous vide ou calorifugé, de distribution de GAZ, sous forme liquide, Azote, oxygène ou CO2 pour les applications industrielles, agroalimentaire, stockage génétique (Biobanque).
						<br />
						- Mise en service et maintenance 
						<br />
						- Réseaux de stockage cryogénique
						<br />
						- Injection et refroidissement, tunnel et mélangeur cryogénique
						<br />
						- Installation de salle cryogénique
						<br />
						- Extraction et évacuation des Gaz Froid de process
					</p>
				</div>
			);
		} else if (infoToDisplay === "gaz_speciaux") {
			setLabel("Gaz Spéciaux");
			setImageDisplay(tab[3].images[tab[tab.findIndex(x => x.name === infoToDisplay)].index]);
			setContent(
				<div>
					<h1>
						Gaz Spéciaux
					</h1>


					<p className='spécificité'>
						Étude, réalisation et maintenance : 
						<br />
						- Des unités de production
						<br />
						- Des panoplies de distribution
						<br />
						- Des réseaux de distribution
						<br />
						- Extraction Gaz de process

						<br />
						<br />
						Suivant vos exigences, nous certifions les dossiers de fabrication suivant les catégories 0, 1 et 2 sur la basse, moyenne et haute pression.
						Montage de réseaux GAZ, assemblage mécanique ou soudure TIG INOX
					</p>
				</div>
			);
		}
	}

    return (
        <div className='info_page'>
            <div className='navhead'>
                <NavBar />
            </div>

            <div className='head_info'>
                <h1>
                    Nos Activités
                </h1>
                <p>
                    Retrouvez ici une déscription de nos différentes activités !
                </p>
            </div>

            <div className="block_info" key={animationKey}>
				<div className='block_info_left'>
					<img src={imageDisplay} />
					<div className='name'>
						<h1>
							{label}
						</h1>
					</div>
					<div className='caroussel_image'>
						<div className='swip_left_img' onClick={() => decreaseIndexOfCurrentInfo()}>
							<AiOutlineLeft />
						</div>

						<div className='swip_right_img' onClick={() => increaseIndexOfCurrentInfo()}>
							<AiOutlineRight />
						</div>
					</div>
				</div>

				<div className='block_info_right'>
					{
						content != null ? content : ''
					}

					<div className='swip'>
						<div className='swip_left' onClick={() => switchInfoDecrease()}>
							<AiOutlineLeft />
						</div>

						<h2>{label}</h2>

						<div className='swip_right' onClick={() => switchInfoIncrease()}>
							<AiOutlineRight />
						</div>
					</div>
				</div>
            </div>

            <footer id="footer" class="bg-color-secondary border-top-0 mt-0 custom-footer">
				<div class="container container-xl-custom py-md-4">
					<div class="row justify-content-md-center py-5">
						<div class="col-md-12 col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start mb-5 mb-lg-0">
							<a href="#"><img src={imgLogo} alt="Logo" class="img-fluid logo" /></a>
						</div>
						<div class="col-md-3 text-center text-md-start">
							<p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Nous Retrouver</p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Adresse</p>
							<p class="text-3 mb-2 text-color-light">13 rue du Chambré, 54760, Faulx - France</p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">TELEPHONE</p>
							<p class="text-3 mb-2 text-color-light"><a href="tel:+1234567890" class="text-color-light">03 83 49 65 54</a></p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">EMAIL</p>
							<p class="text-3 mb-2 "><a href="mailto:info@porto.com" class="text-color-light">contact@sarlperrollaz.fr</a></p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Heures d'ouverture</p>
							<p class="text-3 mb-3 text-color-light">Lun - Ven | 8:30 - 12:30 / 14:00 - 17:00</p>
							<ul class="social-icons social-icons-dark social-icons-clean">
								<li class="social-icons-linkedin">
									<a href="https://www.linkedin.com/in/didier-perrollaz-568b4b140/" target="_blank" title="Linkedin">
										<i class="fab fa-linkedin font-weight-semibold"></i>
									</a>
								</li>
								<li class="social-icons-facebook">
									<a href="https://www.facebook.com/Perrollaz54/" target="_blank" title="Facebook">
										<i class="fab fa-facebook-f font-weight-semibold"></i>
									</a>
								</li>
							</ul>
						</div>
						<div class="col-md-4 text-center text-md-start mt-5 mt-md-0 mb-5 mb-lg-0">
							<p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Navigation</p>
							<div class="row opacity-7">
								<div class="col-md-5">
									<p class="mb-0"><a href="#" class="text-3 text-color-light link-hover-style-1">Accueil</a></p>
									<p class="mb-0"><a href="/informations" onClick={() => localStorage.setItem('CurrentInfo', 'gaz_speciaux')} class="text-3 text-color-light link-hover-style-1">à propos</a></p>
									<p class="mb-0"><a href="/contact" class="text-3 text-color-light link-hover-style-1">Nous contacter</a></p>
									<p class="mb-0"><a href="/login" class="text-3 text-color-light link-hover-style-1">Se connecter</a></p>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="container container-xl-custom">
					<div class="footer-copyright footer-copyright-style-2 bg-color-secondary">
						<div class="py-2">
							<div class="row py-4">
								<div class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">
									<p class="text-3 text-color-light opacity-7">Tal-Web construction. © 2023. All Rights Reserved</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
        </div>
    )
}

export default Information;