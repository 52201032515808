import React from "react";

import NavBar from "../../components/navBar/NavBar";

import { MdLocationOn } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineSchedule } from "react-icons/ai";

import imgLogo from '../../style/mesimages/logo.png';

import "./contact.css";

function Contact() {
    return (
        <div>
            <NavBar />

            <div className="contact">
                <div className="contact__container">
                    <h1>Contactez nous</h1>
                    <p>Utilisez les informations ci-dessous afin de nous contacter</p>
                    <div className="contact__container_both">
                        <div className="contact_right">
                            <div className="contact_right_padd">
                                <h1>Informations de contact</h1>
                                <p>Retrouvez nos différentes informations de contact ci dessous</p>
                                <div className="info_icon">
                                    <div className="icon_contact_left">
                                        <p><MdLocationOn /></p>
                                    </div>

                                    <div className="icon_contact_right">
                                        <h2>Adresse</h2>
                                        <p>13 rue du Chambré, FAULX</p>
                                    </div>
                                </div>

                                <div className="trait"></div>

                                <div className="info_icon">
                                    <div className="icon_contact_left">
                                        <p><AiFillPhone /></p>
                                    </div>

                                    <div className="icon_contact_right">
                                        <h2>Téléphone</h2>
                                        <a href="tel:+33682916423">06 82 91 64 23</a>
                                        <a href="tel:+33383496554">03 83 49 65 54</a>
                                    </div>
                                </div>

                                <div className="trait"></div>

                                <div className="info_icon">
                                    <div className="icon_contact_left">
                                        <p><AiOutlineMail /></p>
                                    </div>

                                    <div className="icon_contact_right">
                                        <h2>Email</h2>
                                        <a href="mailto:contact@sarlperrollaz.fr">contact@sarlperrollaz.fr</a>
                                    </div>
                                </div>

                                <div className="trait"></div>

                                <div className="info_icon">
                                    <div className="icon_contact_left">
                                        <p><AiOutlineSchedule /></p>
                                    </div>

                                    <div className="icon_contact_right">
                                        <h2>Horaires</h2>
                                        <p>Lun - Ven | 8:30 - 12:30 / 14:00 - 17:00</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="contact_left">
                            <iframe
                                title="Google Maps"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                style={{ border: 0, margin: 0, padding: 0}}
                                src={`https://www.google.com/maps?q=13+rue+du+Chambré,+FAULX&output=embed`}
                                allowFullScreen
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>

            <footer id="footer" class="bg-color-secondary border-top-0 mt-0 custom-footer">
				<div class="container container-xl-custom py-md-4">
					<div class="row justify-content-md-center py-5">
						<div class="col-md-12 col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start mb-5 mb-lg-0">
							<a href="#"><img src={imgLogo} alt="Logo" class="img-fluid logo" /></a>
						</div>
						<div class="col-md-3 text-center text-md-start">
							<p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Nous Retrouvez</p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">ADDRESS</p>
							<p class="text-3 mb-2 text-color-light">13 rue du Chambré, 54760, Faulx - France</p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">TELEPHONE</p>
							<p class="text-3 mb-2 text-color-light"><a href="tel:+1234567890" class="text-color-light">03 83 49 65 54</a></p>
                            <p class="text-3 mb-2 text-color-light"><a href="tel:+1234567890" class="text-color-light">06 82 91 64 23</a></p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">EMAIL</p>
							<p class="text-3 mb-2 "><a href="mailto:info@porto.com" class="text-color-light">contact@sarlperrollaz.fr</a></p>
							<p class="text-3 mb-0 font-weight-bold text-color-light opacity-7 text-uppercase">Heures d'ouverture</p>
							<p class="text-3 mb-3 text-color-light">Lun - Ven | 8:30 - 12:30 / 14:00 - 17:00</p>
							<ul class="social-icons social-icons-dark social-icons-clean">
								<li class="social-icons-linkedin">
									<a href="https://www.linkedin.com/in/didier-perrollaz-568b4b140/" target="_blank" title="Linkedin">
										<i class="fab fa-linkedin font-weight-semibold"></i>
									</a>
								</li>
								<li class="social-icons-facebook">
									<a href="https://www.facebook.com/Perrollaz54/" target="_blank" title="Facebook">
										<i class="fab fa-facebook-f font-weight-semibold"></i>
									</a>
								</li>
							</ul>
						</div>
						<div class="col-md-4 text-center text-md-start mt-5 mt-md-0 mb-5 mb-lg-0">
							<p class="text-5 text-color-light font-weight-bold mb-3 mt-4 mt-lg-0">Navigation</p>
							<div class="row opacity-7">
								<div class="col-md-5">
									<p class="mb-0"><a href="#" class="text-3 text-color-light link-hover-style-1">Accueil</a></p>
                                    <p class="mb-0"><a href="/informations" onClick={() => localStorage.setItem('CurrentInfo', 'gaz_speciaux')} class="text-3 text-color-light link-hover-style-1">à propos</a></p>
                                    <p class="mb-0"><a href="/contact" class="text-3 text-color-light link-hover-style-1">Nous contactez</a></p>
                                    <p class="mb-0"><a href="/login" class="text-3 text-color-light link-hover-style-1">Se connecter</a></p>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="container container-xl-custom">
					<div class="footer-copyright footer-copyright-style-2 bg-color-secondary">
						<div class="py-2">
							<div class="row py-4">
								<div class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">
									<p class="text-3 text-color-light opacity-7">Tal-Web construction. © 2023. All Rights Reserved</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
        </div>
    );
}

export default Contact;