import './App.css';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Accueuil from './pages/home/Accueuil';
import NotFound from './pages/notfound/notFound';
import Contact from './pages/contact/contact';
import Devis from './pages/devis/devis';
import Information from './pages/information/information';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/dashboard';

function App() {
    return (
        <div className="App">
           <BrowserRouter>
                <Routes>
                <Route path="/" element={<Accueuil />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/informations" element={<Information />} />
                <Route path="/:slug" element={<Devis />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/contact" element={<Contact />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
